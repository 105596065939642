.menu-page{
  font-family: 'Vollkorn', serif;
  width: 100%;
  .container{
    margin-bottom: 175px;
    .menu{
      &-item{
        margin-bottom: 80px;
      }
      &-list{
        width: 100%;
        clear: both;
        &:after{content:".";display:block;height:0;font-size:0;clear:both;visibility:hidden}
        dt{
          float: left;
          width: 70%;
          margin-bottom: 10px;
        }
        dd{
          span{
            float: right;
          }
        }
      }
    }
  }
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .staff-page{
    margin-bottom: 175px;
    font-family: 'Vollkorn', serif;
    .container{
      margin-bottom: 175px;
      text-align: center;
    }
  }
}