header{
  position: relative;
  .reservation_btn{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 105;
    width: 401px;
    height: 50px;
  }
}
.header{
  &-concept{
    margin-bottom: 60px;
    background: url("../img/common/bg.png") no-repeat;
    background-size: cover;
    padding: 48px 0;
    font-family: 'Vollkorn', serif;
    text-align: center;
    background-position: 50% 50%;
    height: 240px;
    position: relative;
    margin-bottom: 0px;
    h1{
      width: 152px;
      height: 48px;
      img{
        width: 100%;
      }
    }
    h2{
      font-size: 30px;
      font-weight: normal;
      letter-spacing: .3em;
      display: inline-block;
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      color: #84959e;
    }
    p{
      margin: 0;
      animation: fadeIn 4s ease 0s 1 normal;
      -webkit-animation: fadeIn 4s ease 0s 1 normal;
    }
  }
}

// 中デバイス（タブレット, 768px 以上）
@media (max-width: 767px) {
  header{
    position: relative;
    .reservation_btn{
      display: none;
    }
  }
  .header {
    &-concept {
      margin-bottom: 100px !important;
    }
  }
}