.home-page{
  .main_img{
    height: 300px;
    background: url("../img/common/img01.png") no-repeat;
    background-size: cover;
    background-position: 50% 80%;
    position: relative;
    &-logo{
      width: 180px;
      height: 124px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      animation: fadeIn 5s ease 0s 1 normal;
      -webkit-animation: fadeIn 5s ease 0s 1 normal;
      img{
        width: 100%;
      }
    }
  }
  .home{
    &-concept{
      margin-bottom: 60px;
      background: url("../img/common/bg.png") no-repeat;
      background-size: cover;
      padding: 48px 0;
      font-family: 'Vollkorn', serif;
      text-align: center;
      background-position: 50% 50%;
      h2{
        font-size: 22px;
        font-weight: normal;
        letter-spacing: .3em;
        display: inline-block;
        margin-bottom: 20px;
      }
      p{
        margin: 0;
        animation: fadeIn 4s ease 0s 1 normal;
        -webkit-animation: fadeIn 4s ease 0s 1 normal;
      }
    }
    &-staff{
      text-align: center;
      margin-bottom: 60px;
    }
    &-news{
      text-align: center;
      margin-bottom: 60px;
      &-list{
        border-top: 1px solid #d9d9d9;
        margin-bottom: 40px;
        li{
          a{
            padding: 20px ;
            border-bottom: 1px solid #d9d9d9;
            text-align: left;
            display: inline-block;
            width: 100%;
            .date{
              margin-right: 30px;
              color: #84959e;
            }
            .news-text{
              color: #84959e;
            }
            &:hover{
              background: #f5f8f9;
            }
          }
        }
      }
    }
  }
}
.slick-slide{
  .slick-item{
    margin: 0 10px;
  }
  a{
    padding: 0px 20px;
    display: block;
  }
  img {
    display: block;
    margin-bottom: 20px;
  }
  dl{
    font-family: 'Vollkorn', serif;
    letter-spacing: .3em;
    dt{font-size: 18px;}
    dd{
      border-top: 1px solid #dcebf0;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

// CSS
.move{
  opacity: 0;
  transform: translate(0,10px);
  -webkit-transform: translate(0,10px);
  transition: 0s;
  animation: fadeIn 5s ease 0s 1 normal;
  -webkit-animation: fadeIn 5s ease 0s 1 normal;
}
.move-up{
  opacity: 1.0;
  transform: translate(0,0);
  -webkit-transform: translate(0,0);
  animation: fadeIn 5s ease 0s 1 normal;
  -webkit-animation: fadeIn 5s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}


// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .home-page{
    .main_img{
      height: 570px;
      &-logo{
        width: 380px;
        height: 258px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        animation: fadeIn 5s ease 0s 1 normal;
        -webkit-animation: fadeIn 5s ease 0s 1 normal;
        img{
          width: 100%;
        }
      }
    }
    .home{
      &-concept{
        margin-bottom: 175px;
        h2{
          font-size: 22px;
          font-weight: normal;
          letter-spacing: .3em;
          display: inline-block;
          margin-bottom: 20px;
        }
        p{
          margin: 0;
          animation: fadeIn 4s ease 0s 1 normal;
          -webkit-animation: fadeIn 4s ease 0s 1 normal;
          line-height: 2.5;
          letter-spacing: .06em;
        }
      }
      &-staff{
        margin-bottom: 175px;
      }
      &-news{
        margin-bottom: 175px;
      }
    }
  }
}