.access-page{
  margin-bottom: 175px;
  font-family: 'Vollkorn', serif;
  width: 100%;
  .container{
    margin-bottom: 175px;
    text-align: center;
    .access-map{
      position: relative;
      padding-bottom: 200px;
      height: 0;
      overflow: hidden;
      iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:200px;
      }
    }
  }
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .access-page{
    margin-bottom: 175px;
    font-family: 'Vollkorn', serif;
    .container{
      margin-bottom: 175px;
      text-align: center;
      .access-map{
        position: relative;
        padding-bottom: 438px;
        height: 0;
        overflow: hidden;
        iframe, object, embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height:438px;
        }
      }
    }
  }
}