.news-page{
  margin-bottom: 175px;
  font-family: 'Vollkorn', serif;
  width: 100%;
  .news{
    &-list{
      border-top: 1px solid #d9d9d9;
      margin-bottom: 40px;
      li{
        a{
          padding: 20px ;
          border-bottom: 1px solid #d9d9d9;
          text-align: left;
          display: inline-block;
          width: 100%;
          .date{
            margin-right: 30px;
            color: #84959e;
          }
          .news-text{
            color: #84959e;
          }
          &:hover{
            background: #f5f8f9;
          }
        }
      }
    }
  }
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .news-page{
    margin-bottom: 175px;
    font-family: 'Vollkorn', serif;
    .container{
      margin-bottom: 175px;
      text-align: center;
      .access-map{
        position: relative;
        padding-bottom: 438px;
        height: 0;
        overflow: hidden;
        iframe, object, embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height:438px;
        }
      }
    }
  }
}