.staff-page{
  margin-bottom: 175px;
  font-family: 'Vollkorn', serif;
  width: 100%;
  .container{
    margin-bottom: 175px;
    text-align: center;
    .staff{
      &-header{
        margin-bottom: 60px;
        .staff-item{
          display: block;
          padding: 0 30px;
          width: 346px;
          margin: 0 auto;
          img {
            display: block;
            margin-bottom: 20px;
            width: 100%;
            border-radius: 50%;
          }
          dl{
            font-family: 'Vollkorn', serif;
            letter-spacing: .3em;
            dt{font-size: 18px;}
            dd{
              border-top: 1px solid #dcebf0;
              margin-top: 10px;
              padding-top: 10px;
              p{
                margin-bottom: 10px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
      &-sns{
        .sns-list{
          margin: 0 auto 100px auto;
          position: relative;
          left: 50%;
          float: left;
          li{
            height: 20px;
            position: relative;
            left: -50%;
            float: left;
            img{
              margin: 0 10px;
              height: 20px;
            }
          }
        }
      }
      &-text{
        text-align: left;
      }
      &-list{
        margin-bottom: 60px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        &-header{
          margin-bottom: 175px;
        }
        .staff-item{
          display: block;
          padding: 0px 30px;
          width: 240px;
          img {
            display: block;
            margin-bottom: 20px;
            width: 100%;
            border-radius: 50%;
          }
          dl{
            font-family: 'Vollkorn', serif;
            letter-spacing: .3em;
            dt{font-size: 18px;}
            dd{
              border-top: 1px solid #dcebf0;
              margin-top: 10px;
              padding-top: 10px;
              p{
                margin-bottom: 10px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }
    }


  }
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .staff-page{
    margin-bottom: 175px;
    font-family: 'Vollkorn', serif;
    .container{
      margin-bottom: 175px;
      text-align: center;
    }
  }
}