.mw_wp_form_content{
  .mw_wp_form{
    input,textarea{
      padding: 10px;
      margin-bottom: 20px;
      width: 100%;
      border-radius: 4px;
      border: 1px #ccc solid;
    }
  }
}
input[type="submit"]{
  border: 1px solid #84959e;
  padding: 5px 28px 2px 28px;
  color: #84959e;
  font-family: 'Vollkorn', serif;
  letter-spacing: .3em;
  display: inline-block;
  background-size: 6px;
  font-size: 14px;
  margin-bottom: 175px;
  background: #fff;
  &:hover{
    background: #dcebf0;
    color: #84959e;
  }
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .access-page{
    margin-bottom: 175px;
    font-family: 'Vollkorn', serif;
    .container{
      margin-bottom: 175px;
      text-align: center;
      .access-map{
        position: relative;
        padding-bottom: 438px;
        height: 0;
        overflow: hidden;
        iframe, object, embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height:438px;
        }
      }
    }
  }
}