.footer{
  &-info{
    background: #dcebf0;
    padding:42px 0;
    &-content{
      text-align: center;
      h3{
        width: 168px;
        margin: 0 auto;
        img{
          width: 100%;
        }
      }
      p{
        color: #84959e;
      }
      .sns-list{
        display: flex;
        width: 170px;
        margin: 0 auto;
        li{
          height: 20px;
          img{
            margin: 0 10px;
            height: 20px;
          }
        }
      }
    }
  }
  &-map{
    position: relative;
    padding-bottom: 200px;
    height: 0;
    overflow: hidden;
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height:200px;
    }
  }
  .copy{
    padding: 10px 0;
    margin: 0;
    text-align: center;
    font-size: 8px;
    letter-spacing: .3em;
  }
}