.about-page{
  margin-bottom: 175px;
  font-family: 'Vollkorn', serif;
  width: 100%;
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .about-page{
    margin-bottom: 175px;
    font-family: 'Vollkorn', serif;
  }
}