// Bourbon
// See: http://bourbon.io/docs/
@import 'bourbon';

// Susy
// Susy grid system. See: http://susydocs.oddbird.net/en/latest/
@import 'susy';

// Utilities
// Sass tools and helpers used across the project.
// @import 'utils/variables';
// @import 'utils/mixins';

// Vendors
// External libraries and frameworks.
@import 'vendors/slick'; // Imported via node_modules.
@import 'vendors/slick-theme';
@import 'vendors/bootstrap/bootstrap-grid.min';
@import 'vendors/bootstrap/bootstrap-reboot.min';
@import 'vendors/bootstrap/bootstrap.min';
@import 'vendors/drawer';

// Base
// Includes all the main Storefront css.
@import 'base/base';
// @import 'base/layout';


@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/global/_layout.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/global/_typography.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/global/_utility.scss";

@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/components/_footer.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/components/_header.scss";

@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_about.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_access.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_contact.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_home.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_menu.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_news.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_privacy.scss";
@import "/Users/takemotoyasushi/local/Projects/salon-takahiro-wp/src/scss/pages/_staff.scss";








