
@import url('https://fonts.googleapis.com/css?family=Vollkorn:400,400i,600,600i,700,700i,900,900i');

.title{
  &-h2{
    margin-bottom: 65px;
    border-bottom: 2px solid #dcebf0;
    font-family: 'Vollkorn', serif;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: .3em;
    display: inline-block;
    color: #84959e;
    padding-left: 5px;
  }
   &-h3{
     margin-bottom: 24px;
     border-bottom: 2px solid #dcebf0;
     font-family: 'Vollkorn', serif;
     font-size: 18px;
     font-weight: normal;
     letter-spacing: .3em;
     display: inline-block;
   }
}


.btn{
  &-nomal{
    a{
      border: 1px solid #84959e;
      padding: 5px 28px 0px 28px;
      color: #84959e;
      font-family: 'Vollkorn', serif;
      letter-spacing: .3em;
      display: inline-block;
      span{
        background-size: 6px;
        font-size: 14px;
      }
      &:hover{
        background: #dcebf0;
        color: #84959e;
      }
    }
  }
  &-arrow{
    a{
      border: 1px solid #84959e;
      padding: 5px 28px 0px 28px;
      color: #84959e;
      font-family: 'Vollkorn', serif;
      letter-spacing: .3em;
      display: inline-block;
      span{
        background: url(../img/common/btn-arrow.svg) top 2px left no-repeat;
        background-size: 6px;
        font-size: 14px;
        padding-left: 16px;
      }
      &:hover{
        background: #dcebf0;
        color: #84959e;
      }
    }
  }
}



.linkcolor, a:active, a:focus, a:hover {
  text-decoration: none;
  color: #444;
}


a:hover {
    text-decoration: none;
    color: #444;
}

.heading-blue {
    border-left: 5px solid #669dd2;
}

.dl{
  &-text{
    dt{margin-bottom: 10px;
      font-size: 16px;}
    dd{
      margin-bottom: 20px;
    }
  }
}

.ul{
  li{
    margin-bottom: 10px;
  }
}

.post{
  &-text{
    text-align: left;
    margin-bottom: 100px;
  }
}

/*【font】*/
.font{
  &-bold{font-weight: bold;}
  &-normal{font-weight: normal;}
  &-size10{font-size: 10px;}
  &-size11{font-size: 11px;}
  &-size12{font-size: 12px;}
  &-size13{font-size: 13px;}
  &-size14{font-size: 14px;}
  &-size15{font-size: 15px;}
  &-size16{font-size: 16px;}
  &-size17{font-size: 17px;}
  &-size18{font-size: 18px;}
  &-size19{font-size: 19px;}
  &-size20{font-size: 20px;}
  &-size21{font-size: 21px;}
  &-size22{font-size: 22px;}
  &-size23{font-size: 23px;}
  &-size24{font-size: 24px;}
  &-size25{font-size: 25px;}
  &-size26{font-size: 26px;}
  &-size27{font-size: 27px;}
  &-size28{font-size: 28px;}
  &-size29{font-size: 29px;}
  &-size30{font-size: 30px;}
  &-size31{font-size: 31px;}
  &-size32{font-size: 32px;}
  &-size33{font-size: 33px;}
  &-size34{font-size: 34px;}
  &-size35{font-size: 35px;}
  &-size36{font-size: 36px;}
  &-size37{font-size: 37px;}
  &-size38{font-size: 38px;}
  &-size39{font-size: 39px;}
  &-size40{font-size: 40px;}
}

/*【text】*/
.text{
  &-left{text-align: left;}
  &-right{text-align: right;}
}



// 小デバイス（横向きモバイル, 576px 以上）
@media (min-width: 576px) {
  // sm
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  // md
.md {
    &-font{
      &-size14{font-size: 14px;}
    }
}
}
// 大デバイス（デスクトップ, 992px 以上）
@media (min-width: 992px) {
  // lg
}

// 特大デバイス（ワイド・デスクトップ, 1200px 以上）
@media (min-width: 1200px) {
  // xl
}