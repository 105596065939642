.staff-page{
  margin-bottom: 175px;
  font-family: 'Vollkorn', serif;
  width: 100%;
  .container{
    margin-bottom: 175px;
    text-align: center;



  }
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .staff-page{
    margin-bottom: 175px;
    font-family: 'Vollkorn', serif;
    .container{
      margin-bottom: 175px;
      text-align: center;
    }
  }
}