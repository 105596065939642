.container{
  width: 100%;
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .container{
    width: 750px;
  }
}


/*【display】*/
.flex{display: flex;
  &-col2{
    display: flex;
    flex-wrap:wrap;
    &-item{
      width: 48.4%;
      margin-right: 30px;
      margin-bottom: 30px;
      vertical-align: top;
    }
    &-item:nth-child(2n){
      margin-right: 0px;
    }
  }
  &-col3{
    display: flex;
    flex-wrap:wrap;
    &-item{
      width: 31.2%;
      margin-right: 30px;
    }
    &-item:nth-child(3n){
      margin-right: 0px;
    }
  }
  &-col4{
    display: flex;
    flex-wrap:wrap;
    &-item{
      width: 21.8%;
      margin-right: 30px;
    }
    &-item:nth-child(4n){
      margin-right: 0px !important;
    }
  }
}


@media only screen and (max-width: 768px) {
  .flex{display: block;
    &-col2{
      display: flex;
      flex-wrap:wrap;
      &-item{
        margin-right: 6px;
        margin-bottom: 30px;
        vertical-align: top;
      }
    }
    &-col3{
      display: block;
      &-item{
        width: 100%;
        margin-right: 0;
      }
    }
    &-col4{
      display: flex;
      flex-wrap:wrap;
      &-item{
        width: 48.6%;
        margin-right: 10px;
      }
      &-item:nth-child(2n){
        margin-right: 0px !important;
      }
    }
  }
}






.pagenav {
  overflow: hidden;
  .pagenav-content{
    list-style: none;
    position: relative;
    left: 50%;
    float: left;
    li {
      margin: 0 5px;
      position: relative;
      left: -50%;
      float: left;
      background-color: #fff;
      border:1px solid #84959e;
      font-size: 16px;
      span,a {
        display: block;
        padding: 0px 16px;
      }
      .current{
        color: #fff;
        background-color: #84959e;
        border:1px solid #84959e;
      }
      &:hover{
        border:1px solid #84959e;
        color: #fff;
        background-color: #84959e;
        a{
          color: #fff;
        }
      }
    }
    &:after{content:".";display:block;height:0;font-size:0;clear:both;visibility:hidden}
  }
}
